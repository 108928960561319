/*
When local, retrieve settings from local environment with staging values as default
We should migrate to all environments getting all config from environment
However there are deployment process to consider
*/
const local = {
  ENV: "Local",
  ENABLE_SENTRY: false,
  GA_ID: "",
  API_CONNECT_ROOT:
    process.env.REACT_APP_API_CONNECT_ROOT ||
    "https://connect.staging.passthekeys.com/api/v3",
  API_AUTH_ROOT:
    process.env.REACT_APP_API_AUTH_ROOT || "https://auth.staging.passthekeys.com",
};

const cd_env = {
  ENV: "production",
  ENABLE_SENTRY: "true" === "true" ? true : false,
  GA_ID: "UA-61548991-6",
  API_CONNECT_ROOT: "https://ptkconnect.com/api/v3",
  API_AUTH_ROOT: "https://auth.passthekeys.com",
};

const config = process.env.REACT_APP_PTK_ENV === "local" ? local : cd_env;

export default config;
